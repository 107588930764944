import React from 'react';
import "../Cards/card.css";
import { Card, Stack } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa'; // Importing the icons

const PopularCard = ({ val }) => {
  // Function to handle WhatsApp redirection with prefilled message
  const handleWhatsAppClick = () => {
    const phoneNumber = "9557661454";
    const message = `Hello! Can I get more info on this?\n\nTour: ${val.title}`;
    
    // Encode the message to be URL friendly
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Open WhatsApp link in a new tab/window
    window.open(whatsappUrl, '_blank');
  };

  // Function to handle phone call
  const handleCallClick = () => {
    window.open(`tel:9557661454`, '_self');
  };

  return (
    <>
      <Card className="rounded-2 shadow-sm popular">
        <Card.Img
          variant="top"
          src={val.image}
          className="img-fluid"
          alt={"Mussoorie Taxi Stand"}
          style={{ height: 200, width: 400, objectFit: 'cover' }}
        />
        <Card.Body>
          <Card.Text>
            <i className="bi bi-geo-alt"></i>
            <span className="text mt-2 font-bold font-mono">{val.location}</span>
          </Card.Text>

          <Card.Title>
            <NavLink className="body-text text-dark text-decoration-none font-bold font-mono">
              {val.title}
            </NavLink>
          </Card.Title>
        </Card.Body>

        <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg shadow-md">
  {/* Call Icon */}
  <FaPhoneAlt
    onClick={handleCallClick}
    className="cursor-pointer mr-4 text-blue-600 hover:text-blue-800 transition duration-200"
    size={24}
  />

  {/* WhatsApp Icon */}
  <FaWhatsapp
    onClick={handleWhatsAppClick}
    className="cursor-pointer text-green-600 hover:text-green-800 transition duration-200"
    size={28}
  />
</div>

      </Card>
    </>
  );
};

export default PopularCard;
