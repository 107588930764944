import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-gray-100 py-20">
      <div className="max-w-6xl bg-white mx-auto px-5">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-6">
          Welcome to Mussoorie Taxi Stand
        </h1>

        <p className="text-lg text-gray-700 mb-4 leading-relaxed">
          **Mussoorie**, known as the "Queen of the Hills," is a beautiful hill station in India. Located in the Himalayas, it is famous for its stunning views, pleasant weather, and rich culture. At <span className="font-semibold">Mussoorie Taxi Stand</span>, we provide the best taxi services to help you explore this wonderful place. Our owner, <span className="font-semibold">Virendra Punder</span>, and our dedicated team are here to ensure you have a safe and enjoyable journey.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Why Choose Us?</h2>
        <p className="text-lg text-gray-700 mb-4">
          Here’s why you should book your taxi with us:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-6">
          <li><strong>Reliable Service:</strong> We are committed to being on time, every time.</li>
          <li><strong>Affordable Prices:</strong> Enjoy competitive rates without hidden costs.</li>
          <li><strong>Local Knowledge:</strong> Our experienced drivers know the best routes and attractions.</li>
          <li><strong>24/7 Availability:</strong> Whether it’s early morning or late night, we are here for you.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Services</h2>
        <p className="text-lg text-gray-700 mb-4">
          We offer a variety of taxi services to meet your needs:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-6">
          <li><strong>Local Rides:</strong> Need a quick ride around Mussoorie? We’ve got you covered.</li>
          <li><strong>Airport Transfers:</strong> We provide hassle-free transfers to Jolly Grant Airport.</li>
          <li><strong>Sightseeing Tours:</strong> Explore attractions like Kempty Falls, Gun Hill, and more.</li>
          <li><strong>Group Bookings:</strong> We can accommodate large groups for tours and events.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Explore Mussoorie with Us</h2>
        <p className="text-lg text-gray-700 mb-4">
          Mussoorie offers many attractions that you shouldn’t miss:
        </p>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-6">
          <li><strong>Kempty Falls:</strong> A beautiful waterfall perfect for picnics.</li>
          <li><strong>Lal Tibba:</strong> The highest point in Mussoorie with breathtaking views.</li>
          <li><strong>Gun Hill:</strong> A popular hill accessible by a cable car.</li>
          <li><strong>Mall Road:</strong> A vibrant shopping street with various shops and eateries.</li>
          <li><strong>Nearby Attractions:</strong> Visit Dhanaulti, Landour, and Tehri Lake for more fun!</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Customer Feedback</h2>
        <p className="text-lg text-gray-700 mb-4">
          We love hearing from our customers! Here’s what some have to say:
        </p>
        <blockquote className="border-l-4 border-blue-500 pl-4 italic text-gray-600 mb-6">
          "Mussoorie Taxi Stand provided excellent service! The driver was very friendly." – A happy customer
        </blockquote>
        <blockquote className="border-l-4 border-blue-500 pl-4 italic text-gray-600 mb-6">
          "Great prices and reliable service! Highly recommend!" – Another satisfied traveler
        </blockquote>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Us</h2>
        <p className="text-lg text-gray-700 mb-4">
          Ready to book your taxi? Reach out to us:
        </p>
        <div className="text-lg text-gray-700 mb-6">
          <p><strong>Mobile:</strong> <a href="tel:+919557661454" className="text-blue-500">+91 9557661454</a></p>
          <p><strong>WhatsApp:</strong> <a href="https://wa.me/919557661454" className="text-blue-500">Chat with us on WhatsApp</a></p>
          <p><strong>Owner:</strong> Virendra Punder</p>
        </div>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Join Us for Your Journey!</h2>
        <p className="text-lg text-gray-700 mb-4">
          At <span className="font-semibold">Mussoorie Taxi Stand</span>, we are passionate about making your travel experience unforgettable. Book your ride today and discover the beauty of Mussoorie with us!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
