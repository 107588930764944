import React from "react";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa"; // Importing icons
import Banner from "../../components/Banner/Banner";
import AdvanceSearch from "../../components/AdvanceSearch/AdvanceSearch";
import Features from "../../components/Features/Features";
import { Container, Row, Col } from "react-bootstrap";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./home.css";

import Gallery from "../../components/Gallery/Gallery";
import Cards from "../../components/Cards/Cards";
import { destinationsData, popularsData } from "../../utils/data";
import PopularCard from "../../components/Cards/PopularCard";

const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  return (
    <>
      <Banner />
      <Features />

      {/* tour section start */}
      <section className="tours_section slick_slider">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1>Top Destination For Your Next Vacation</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Slider {...settings}>
                {destinationsData.map((destination, inx) => {
                  return <Cards destination={destination} key={inx} />;
                })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>

      {/* popular section start */}
      <section className="popular py-5">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1>Popular Activities</h1>
              </div>
            </Col>
          </Row>
          <Row>
            {popularsData.map((val, inx) => {
              return (
                <Col md={3} sm={6} xs={12} className="mb-5" key={inx}>
                  <PopularCard val={val} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>

      <section className="call_us">
  <Container>
    <Row className="align-items-center">
      <Col md="8">
        <h5 className="title">BOOK YOUR RIDE NOW</h5>
        <h2 className="heading">
          PLANNING A TRIP TO MUSSOORIE? WE'RE JUST A CALL AWAY!
        </h2>
        <p className="text">
          Experience the best taxi service in Mussoorie with comfortable rides,
          affordable fares, and professional drivers. Whether you're exploring the hills 
          or need a hassle-free ride to nearby destinations, Mussoorie Taxi Stand is here to serve you.
        </p>
      </Col>
      <Col md="4" className="text-center mt-3 mt-md-0">
        <a href="tel:9557661454" className="secondary_btn bounce" rel="no">
          Call Now for a Quick Pickup!
        </a>
      </Col>
    </Row>
  </Container>
  <div className="overlay"></div>
</section>


      <section className="gallery">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading">
                <h1>Photo Gallery</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Gallery />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Icons for Call and WhatsApp */}
      <div className="fixed bottom-5 left-5 bg-blue-500 p-3 rounded-full shadow-lg">
        <a href="tel:9557661454">
          <FaPhoneAlt className="text-white text-2xl" />
        </a>
      </div>

      <div className="fixed bottom-5 right-5 bg-green-500 p-3 rounded-full shadow-lg">
        <a
          href="https://wa.me/9557661454"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className="text-white text-2xl" />
        </a>
      </div>
    </>
  );
};

export default Home;
